export const BASE_URL= "https://sbif5udtm9.us-east-1.awsapprunner.com";
//export const BASE_URL= "http://localhost:8080";
export const TELEPHONIC_INTERVIEW_TEMPLATE = `
Subject: Telephonic Interview Scheduled

Dear [Candidate Name],

We are pleased to inform you that you have been selected to participate in a telephonic interview as part of our hiring process.

Interview Details:
Date: [Interview Date]
Time: [Interview Time] EST
Interviewer: [Interviewer]



Please make sure to be available at the specified time and in a quiet environment to facilitate a smooth interview experience. The interviewer will call you at the provided phone number.

If you have any scheduling conflicts or questions, please let us know as soon as possible.

Best regards,
HR

`;
export const F2F_INTERVIEW_TEMPLATE = `
Subject: Face to Face Interview Scheduled

Dear [Candidate Name],

We are pleased to inform you that you have been selected to participate in a Face to Face inperson interview as part of our hiring process.

Interview Details:
Date: [Interview Date]
Time: [Interview Time] EST
Interviewer: [Interviewer]



Please make sure to be available at the location 15 mins prior to the interview. 

If you have any scheduling conflicts or questions, please let us know as soon as possible.

Best regards,
HR

`;



export const VIDEO_INTERVIEW_TEMPLATE = `
Subject: Video Interview Scheduled

Dear [Candidate Name],

Congratulations! You have been selected to participate in a video interview as part of our hiring process.

Interview Details:
Date: [Interview Date]
Time: [Interview Time] EST
Interviewer: [Interviewer]



Please use the following link to join the video interview: [Video Interview Link]

Make sure you have a stable internet connection and a quiet environment for the interview. If you encounter any technical difficulties, please contact us .

If you have any scheduling conflicts or questions, please let us know as soon as possible.

We look forward to meeting you virtually!

Best regards,
HR
`;

export const HR_INTERVIEW_TEMPLATE = `
Subject: Video HR Interview Scheduled

Dear [Candidate Name],

Congratulations! You have successfully advanced to the HR round of our hiring process. We would like to invite you to a video HR interview.

Interview Details:
Date: [Interview Date]
Time: [Interview Time] EST
Interviewer: [Interviewer]



Please use the following link to join the video HR interview: [Video Interview Link]

During this interview, we will discuss your qualifications, experience, and our company culture. Be prepared to share more about yourself and ask any questions you may have about our organization.

Make sure you have a stable internet connection and a quiet environment for the interview. If you encounter any technical difficulties, please contact us.

If you have any scheduling conflicts or questions, please let us know as soon as possible.

We look forward to meeting you virtually!

Best regards,
HR
`;

export const DOTIT= "hr@dotitsol.com";
export const IQUBE= "hr@iqube.com";
export const VRKIT= "hr@vrkit.com";


